import React, { Fragment } from "react";

import ArticlePage from "../components/ArticlePage";

function Url() {
  return (
    <Fragment>
      <div className="centering">
        <ArticlePage />
      </div>
    </Fragment>
  );
}

export default Url;
