import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

export default function Impressum() {
  const navigate = useNavigate();
  function handleClick() {
    navigate(`/`);
  }

  return (
    <Fragment>
      <div className="centering margin-rund">
        <p>
          Dieser Webauftritt ist ein Produkt der Manachlux UG
          (haftungsbeschränkt)
        </p>{" "}
        <p>
          <a
            href="https://manachlux.com"
            target="manachlux.com"
            rel="noopener noreferrer"
          >
            www.manachlux.com
          </a>
        </p>
        <p>Besselstr. 25, 68219 Mannheim</p>
        <p>
          Eingetragen im Handelsregister - Registergericht: Amtsgericht
          Mannheim, Registernummer HRB 733705
        </p>
        <p>Vertreten durch Geschäftsführer David Sheikh</p>{" "}
        <p>Umsatzsteuer-Identifikationsnummer: DE325044873</p>{" "}
        <p>Telefon: 0621 97688197</p>
        <p>Email: manachlux@gmail.com</p>
        <p>Verantwortlich für den Inhalt nach §55 Abs 2 RStV: David Sheikh</p>
        <button onClick={handleClick}>Zurück</button>
      </div>
    </Fragment>
  );
}
