import React, { useContext } from "react";
import AppContext from "../../provider/AppContext";
import { useNavigate } from "react-router-dom";

export default function HomeButton() {
  const { language } = useContext(AppContext);

  const homeb = {
    de: "Start",
    en: "Home",
  };

  const navigate = useNavigate();

  function goHome() {
    navigate(`/${language}`);
  }

  return (
    <span className="pointer" onClick={goHome}>
      {homeb[language]}
    </span>
  );
}
