import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NewsContext from "./provider/NewsContext";
import "./App.css";
import Main from "./pages/main";
import Articles from "./pages/articles";
import Url from "./pages/url";
import Impressum from "./pages/impressum";
import Datenschutz from "./pages/datenschutz";

function App() {
  return (
    <>
      <NewsContext>
        <Router>
          <Routes>
            <Route exact path="/" element={<Main />} />
            <Route exact path="/:language/:url" element={<Url />} />
            <Route exact path="/:language" element={<Articles />} />
            <Route exact path="/impressum" element={<Impressum />} />
            <Route exact path="/datenschutz" element={<Datenschutz />} />
          </Routes>
        </Router>
      </NewsContext>
    </>
  );
}

export default App;
