import React, { useState } from "react";
import AppContext from "./AppContext";

const NewsContext = ({ children }) => {
  const [swiv, setSwiv] = useState({ body: "nichtsss" });
  const [language, setLanguage] = useState("en");
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [auf, setAuf] = useState(false);
  const [getOne, setGetOne] = useState({
    headline: "Starting",
    summary: "German politics",
    body: "oneart",
    version: { en: "abc" },
    optional: {
      images: [
        "https://firebasestorage.googleapis.com/v0/b/aihigram.appspot.com/o/platz.jpg?alt=media",
      ],
    },
  });
  const [cookieConsent, setCookieConsent] = useState(false);
  const [allComments, setAllComments] = useState(["leer"]);

  const context = {
    swiv,
    setSwiv,
    language,
    setLanguage,
    isLoading,
    setIsLoading,
    auf,
    setAuf,
    getOne,
    setGetOne,
    loading,
    setLoading,
    cookieConsent,
    setCookieConsent,
    allComments,
    setAllComments,
  };
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
export default NewsContext;
