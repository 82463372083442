import React, { Fragment, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AppContext from "../provider/AppContext";
import Metadecorator from "../components/Metadecorator";
import LangLink from "../components/LangLink";
import Imprint from "./localization/Imprint";
import Back from "./localization/Back";
import More from "./localization/More";
import CommentCount from "./localization/CommentCount";
axios.defaults.baseURL = "https://europe-west1-aihigram.cloudfunctions.net/api";

export default function Articles() {
  const { swiv, setSwiv, language, setLanguage } = useContext(AppContext);

  const useName = () => {
    const location = useLocation();
    return location.pathname;
  };

  const languageProp = useName().split("/")[1];
  console.log("languagepro", languageProp === "");
  languageProp !== "" ? setLanguage(languageProp) : setLanguage("de");

  const navigate = useNavigate();
  function handleClick() {
    navigate("/impressum");
  }

  function clickDS() {
    navigate("/datenschutz");
  }

  function artikelSeite(artikelId) {
    navigate(`/${language}/${artikelId}`);
  }

  const [loading, setLoading] = useState(true);
  const [counter, setCounter] = useState(0);

  function getArticles() {
    console.log("languageProp", languageProp);
    languageProp !== ""
      ? axios.get(`/${languageProp}/main`).then((res) => {
          setSwiv(res.data);
          console.log("setSchools", res.data);

          setLoading(false);
        })
      : axios.get(`/de/main`).then((res) => {
          setSwiv(res.data);
          console.log("setSchools", res.data);

          setLoading(false);
        });
  }

  useEffect(() => {
    getArticles();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <div>
        <div className="app-bar">
          <LangLink />
        </div>
        {loading ? (
          <p>Lädt...</p>
        ) : (
          <div>
            {/* <Metadecorator
              title="Overview"
              description="All Articles"
              content="Content"
            /> */}
            {swiv.map(
              (art, i) => (
                // i < counter + 3 && i > counter - 1 ? (
                <div
                  key={i}
                  className="pointer"
                  onClick={() => artikelSeite(art.articleId)}
                >
                  <div>
                    <p className="ueberschrift">{art.headline}</p>{" "}
                    {art["optional"].images !== "leer" ? (
                      <div>
                        <img
                          className="img-uebersicht"
                          src={art["optional"].images[0]}
                          alt="Something"
                        />
                      </div>
                    ) : null}
                    <p className="summary">{art.summary}</p>
                  </div>
                  {/* <div>
                    {art.commentCount > 0 ? (
                      <CommentCount cnumber={art.commentCount - 1} />
                    ) : (
                      <CommentCount cnumber={art.commentCount} />
                    )}
                  </div> */}
                  <hr />
                </div>
              )
              // ) : null
            )}

            {/* <div className="weiter-zruueck">
                {counter > 0 ? (
                  <p
                    onClick={() => setCounter(counter - 3)}
                    className="pointer"
                  >
                    <Back />
                  </p>
                ) : null}
                {counter < swiv.length + 1 ? (
                  <p
                    onClick={() => setCounter(counter + 3)}
                    className="textcenter pointer"
                  >
                    <More />
                  </p>
                ) : null}
              </div> */}

            <Imprint />
          </div>
        )}
      </div>
    </Fragment>
  );
}
