import { useEffect, useContext } from "react";
import AppContext from "../provider/AppContext";

export default function Tweet({ tweetID }) {
  const { isLoading, setIsLoading } = useContext(AppContext);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.getElementsByClassName("twitter-embed")[0].appendChild(script);
    script.addEventListener("load", () => {
      if (window.twttr) {
        window.twttr.widgets
          .createTweet(tweetID, document.getElementById(tweetID), {
            align: "center",
            conversation: "none",
            dnt: true,
            theme: "dark",
          })
          .then(() => setIsLoading(false));
      }
    });
  }, [tweetID]);

  return (
    <div className="twitter-embed">
      <div className="tweet-breite" id={tweetID}>
        {isLoading && <p>LOADING</p>}
      </div>{" "}
    </div>
  );
}

// twttr.widgets.createTweet:
// https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/guides/embedded-tweet-javascript-factory-function

// Embedded Tweet parameter reference
// https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/guides/embedded-tweet-parameter-reference
