import React, { Fragment, useContext } from "react";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import AppContext from "../provider/AppContext";
import { useLocation } from "react-router-dom";

import polish from "../polish2.jpg";
import english from "../english.jpg";
import french from "../french.jpg";
import german from "../german.jpg";
import italian from "../italian.jpg";
import spanish from "../spain.jpg";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

axios.defaults.baseURL = "https://europe-west1-aihigram.cloudfunctions.net/api";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function LangChoice() {
  const {
    language,
    setLanguage,
    setAuf,
    getOne,
    setGetOne,
    setSwiv,
    setAllComments,
    setLoading,
  } = useContext(AppContext);

  const navigate = useNavigate();

  const useName = () => {
    const location = useLocation();
    return location.pathname;
  };

  const pathLength = useName().split("/").length;
  const pathArr = useName().split("/");

  function getArticles(sprache) {
    console.log("languageProp", sprache);
    axios.get(`/${sprache}/main`).then((res) => {
      setSwiv(res.data);
      console.log("setSchools", res.data);
    });
  }

  function getOneArticle(sprache) {
    axios.get(`/${sprache}/${getOne.version[sprache]}`).then((res) => {
      setGetOne(res.data);
      let commentsArr = Object.values(res.data.comments);
      //   setAllComments(Object.values(res.data.comments));
      console.log("setOne", commentsArr);
      const { compare } = Intl.Collator("en-US");
      commentsArr.sort((a, b) => compare(a.createdAt, b.createdAt));

      setAllComments(commentsArr);
      console.log("wasloggt");
      setLoading(false);
    });
  }

  function artikelSeite(sprache) {
    if (sprache !== language || pathArr[1] === "") {
      setLanguage(sprache);
      if (pathLength < 3 || pathArr[2] === "") {
        getArticles(sprache);
        navigate(`/${sprache}`);
      } else {
        console.log("nachschauen", sprache);
        getOneArticle(sprache);
        navigate(`/${sprache}/${getOne.version[sprache]}`);
      }
    }
    setAuf(false);
  }

  return (
    <Fragment>
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0.5}>
            <Grid item xs>
              <Item className="pointer" onClick={() => artikelSeite("de")}>
                <div>
                  <img className="img-width" src={german} alt="German flag" />
                  <br />
                  <span>Deutsch</span>
                </div>
              </Item>
            </Grid>
            {/* <Grid item xs>
              <Item className="pointer" onClick={() => artikelSeite("fr")}>
                <div>
                  <img className="img-width" src={french} alt="French flag" />{" "}
                  <br />
                  <span>Francais</span>{" "}
                </div>{" "}
              </Item>
            </Grid> */}
            <Grid item xs>
              <Item className="pointer" onClick={() => artikelSeite("en")}>
                <div>
                  <img
                    className="img-width"
                    src={english}
                    alt="Flag of the UK"
                  />{" "}
                  <br />
                  <span>English</span>
                </div>
              </Item>
            </Grid>
            {/* <Grid item xs>
              <Item className="pointer" onClick={() => artikelSeite("pl")}>
                <div>
                  <img className="img-width" src={polish} alt="Polish flag" />{" "}
                  <br />
                  <span>Polish</span>
                </div>
              </Item>
            </Grid>
            <Grid item xs>
              <Item className="pointer" onClick={() => artikelSeite("it")}>
                <div>
                  <img className="img-width" src={italian} alt="Italian flag" />{" "}
                  <br />
                  <span>Italian</span>
                </div>
              </Item>
            </Grid>
            <Grid item xs>
              <Item className="pointer" onClick={() => artikelSeite("es")}>
                <div>
                  <img className="img-width" src={spanish} alt="Spanish flag" />{" "}
                  <br />
                  <span>Spanish</span>
                </div>
              </Item>
            </Grid> */}
          </Grid>
        </Box>
      </div>
    </Fragment>
  );
}
