import React, { Fragment } from "react";
import Articles from "../components/Articles";

export default function Main() {
  return (
    <Fragment>
      <div className="centering">
        <Articles />
      </div>
    </Fragment>
  );
}
