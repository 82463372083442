import React, { useContext } from "react";
import AppContext from "../../provider/AppContext";
import { useNavigate } from "react-router-dom";

export default function Imprint() {
  const { language } = useContext(AppContext);

  const impress = {
    de: "Impressum",
    en: "Imprint",
  };

  const priv = {
    de: "Datenschutz",
    en: "Privacy Policy",
  };

  const navigate = useNavigate();
  function handleClick() {
    navigate("/impressum");
  }

  function clickDS() {
    navigate("/datenschutz");
  }

  return (
    <div className="impressum-ausrichtung pointer">
      <p>
        <span onClick={handleClick}>{impress[language]}</span> <span>|| </span>
        <span onClick={clickDS}>{priv[language]}</span>
      </p>{" "}
    </div>
  );
}
