import React, { useContext } from "react";
import AppContext from "../provider/AppContext";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default function CookieCo() {
  const { cookieConsent, setCookieConsent, language } = useContext(AppContext);

  const cookieFrage = {
    de: "Externe Inhalte von Twitter anzeigen",
    en: "Show external content from Twitter",
  };

  return (
    <div className="consent-div">
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={cookieConsent}
              onChange={() => setCookieConsent(true)}
            />
          }
          label={cookieFrage[language]}
        />
      </FormGroup>
      {/* <p onClick={() => setCookieConsent(true)}>Cookies Akzeptieren</p> */}
    </div>
  );
}
