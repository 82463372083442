import React, { Fragment, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import { useLocation } from "react-router-dom";
import AppContext from "../provider/AppContext";

import Tweet from "../components/Tweet";
import CookieCo from "../components/CookieCo";
import HomeButton from "./localization/HomeButton";
import CommentCount from "./localization/CommentCount";

import Metadecorator from "../components/Metadecorator";
import LangLink from "../components/LangLink";

axios.defaults.baseURL = "https://europe-west1-aihigram.cloudfunctions.net/api";

function ArticlePage() {
  const {
    setLanguage,
    loading,
    setLoading,
    getOne,
    setGetOne,
    cookieConsent,
    allComments,
    setAllComments,
  } = useContext(AppContext);

  const [textbody, setTextbody] = useState("");
  const [usr, setUsr] = useState("");
  const [commentData, setCommentData] = useState({ body: "lalaland" });
  const [cookieOpt, setCookieOpt] = useState(false);

  const useName = () => {
    const location = useLocation();
    return location.pathname;
  };

  const navigate = useNavigate();

  const pathProp = useName().split("/")[2];
  const languageProp = useName().split("/")[1];

  setLanguage(languageProp);

  function getOneArticle() {
    axios.get(`/${languageProp}/${pathProp}`).then((res) => {
      setGetOne(res.data);
      let commentsArr = Object.values(res.data.comments);
      //   setAllComments(Object.values(res.data.comments));
      console.log(
        "setOne",
        commentsArr,
        `/${languageProp}/${pathProp}`,
        res.data
      );
      const { compare } = Intl.Collator("en-US");
      commentsArr.sort((a, b) => compare(a.createdAt, b.createdAt));

      setAllComments(commentsArr);
      const date = new Date(res.data.createdAt._seconds * 1000);
      console.log("wasloggt", commentsArr);
      setLoading(false);
    });
  }

  useEffect(() => {
    getOneArticle();
    checkCookie();
    // eslint-disable-next-line
  }, []);

  // function getComments() {
  //   axios.get(`comments`, { id: pathProp }).then((res) => {
  //     setComms(res.data);
  //     console.log("setComm", res.data);

  //     setLoading(false);
  //   });
  // }

  function postComment(txtbd, usern) {
    if (txtbd !== "") {
      const article = { body: txtbd, userHandle: usern, articleId: pathProp };
      axios.post(`comment`, article).then((res) => setCommentData(res.data));
      setAllComments([{ userHandle: usern, body: txtbd }, ...allComments]);
      setTextbody("");
    }
  }

  function goHome() {
    navigate(`/${languageProp}`);
  }

  // Localization of Posting Comments:

  const writecom = {
    de: "Kommentar schreiben:",
    en: "Write a comment:",
  };

  const nuser = {
    de: "Name",
    en: "Name",
  };

  const nambez = {
    de: "Name",
    en: "Name",
  };

  const cbox = {
    de: "Kommentar",
    en: "Comment",
  };

  const pst = {
    de: "Abschicken",
    en: "Post",
  };

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    setCookieOpt(true);
  }

  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    let user = getCookie("usefriux");
    if (user !== "") {
      setCookieOpt(true);
      // alert("Welcome again " + user);
    } else {
      // user = prompt("Please enter your name:", "");
      // if (user !== "" && user != null) {
      //   setCookie("usefriux", user, 30);
      console.log("Need Cookie consent");
    }
  }

  return (
    <Fragment>
      {/* {cookieOpt ? ( */}{" "}
      {true ? (
        <div>
          <div className="app-bar">
            <HomeButton />
            <LangLink />
          </div>
          {loading ? (
            <p>Lädt...</p>
          ) : (
            <div>
              {/* {loading ? (
                <p>lädt...</p>
              ) : (
                <Metadecorator
                  title={getOne.headline}
                  description={getOne.summary}
                  content="Helmet Conetent"
                  language={languageProp}
                  articleId={pathProp}
                  images={
                    getOne["optional"].images !== "leer"
                      ? getOne["optional"].images[0]
                      : "https://firebasestorage.googleapis.com/v0/b/aihigram.appspot.com/o/platz.jpg?alt=media"
                  }
                  metaprops={getOne.meta}
                />
              )} */}
              <p className="ueberschrift">{getOne.headline}</p>
              <p className="summary">
                {new Date(
                  getOne.createdAt._seconds * 1000
                ).toLocaleDateString()}
              </p>
              <p className="summary">{getOne.summary}</p>
              {getOne["optional"].images !== "leer" ? (
                <div>
                  <img
                    className="img-article"
                    src={getOne["optional"].images[0]}
                    alt="Something"
                  />
                </div>
              ) : null}
              {getOne.tweets !== "leer" ? (
                <div>
                  {getOne.body.map((bod, i) => (
                    <div key={i}>
                      <div className="art-text ">{parse(bod)}</div>
                      {!cookieConsent ? (
                        <div>
                          {i in getOne["tweets"] ? <CookieCo /> : null}{" "}
                        </div>
                      ) : (
                        <div>
                          {i in getOne["tweets"] ? (
                            <div>
                              {getOne["tweets"][`${i}`].map((tw, u) => (
                                <Tweet tweetID={tw} key={tw} />
                              ))}
                            </div>
                          ) : null}{" "}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  {getOne.body.map((bod, i) => (
                    <div key={i}>
                      <div className="art-text ">{parse(bod)}</div>
                    </div>
                  ))}
                </div>
              )}

              <div className="abstand-30"></div>
              {/* <div>
                <CommentCount cnumber={allComments.length - 1} />
                {allComments.map((com, i) =>
                  com !== "leer" ? (
                    <div key={com.userHandle + i}>
                      <p className="comment-handle">{com.userHandle}:</p>
                      <p className="comment-display">{com.body}</p>
                      <hr />
                    </div>
                  ) : null
                )}
                <div className="abstand-30"></div>
                <div>
                  <p>{writecom[languageProp]}</p>
                  <div>
                    {" "}
                    <label htmlFor="name">{nuser[languageProp]}</label>
                    <br />
                    <input
                      type="text"
                      name="name"
                      value={usr}
                      onChange={(e) => setUsr(e.target.value)}
                    />
                  </div>
                  <br />
                  <div>
                    {" "}
                    <label htmlFor="kommentar">{cbox[languageProp]}</label>
                    <br />
                    <textarea
                      rows="5"
                      cols="40"
                      name="kommentar"
                      value={textbody}
                      onChange={(e) => setTextbody(e.target.value)}
                    ></textarea>
                  </div>

                  <button
                    onClick={() => postComment(textbody, usr)}
                    className="pointer"
                  >
                    {pst[languageProp]}
                  </button>
                </div>
              </div> */}
              <div className="abstand-30"></div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <p>
            Klicken Sie "Akzeptieren" um Cookies zu akzeptieren, klicken Sie
            "Ablehnen" um abzulehnen.
          </p>
          <button>Ablehnen</button>
          <button
            onClick={() => setCookie("usefriux", "helvi", 30)}
            className="pointer"
          >
            Akzeptieren
          </button>
        </div>
      )}
    </Fragment>
  );
}

export default ArticlePage;
