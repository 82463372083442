import React, { useContext } from "react";
import LangChoice from "../components/LangChoice";
import AppContext from "../provider/AppContext";

export default function LangLink() {
  const { auf, setAuf, language } = useContext(AppContext);

  const lchoice = {
    de: "Language || Sprache",
    en: "Language || Sprache",
  };

  return (
    <div>
      <span className="pointer" onClick={() => setAuf(!auf)}>
        {lchoice[language]}
      </span>
      <div className="abstand-15"></div>
      {auf ? <LangChoice /> : null}
    </div>
  );
}
